@import '../../style/themes/default';
@import '../../style/mixins/index';

@statistic-prefix-cls: ~'@{ant-prefix}-statistic';

.@{statistic-prefix-cls} {
  .reset-component;

  &-title {
    font-size: @statistic-title-font-size;
    margin-bottom: 4px;
  }

  &-content {
    font-size: @statistic-content-font-size;
    font-family: @statistic-font-family;

    &-value {
      &-decimal {
        font-size: @statistic-unit-font-size;
      }
    }

    &-prefix,
    &-suffix {
      display: inline-block;
    }

    &-prefix {
      margin-right: 4px;
    }

    &-suffix {
      margin-left: 4px;
      font-size: @statistic-unit-font-size;
    }
  }
}

.iconfont-mixin() {
  display: inline-block;
  font-style: normal;
  vertical-align: -0.125em; // for SVG icon, see https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  > * {
    line-height: 1;
  }

  svg {
    display: inline-block;
  }

  &:before {
    display: none; // dont display old icon.
  }

  & &-icon {
    display: block;
  }
}

// for iconfont font size
// fix chrome 12px bug, support ie
.iconfont-size-under-12px(@size, @rotate: 0deg) {
  display: inline-block;
  @font-scale: unit(@size / 12px);
  font-size: 12px;
  // IE9
  font-size: ~'@{size} \9';
  transform: scale(@font-scale) rotate(@rotate);
  :root & {
    font-size: @font-size-sm; // reset IE9 and above
  }
}

@import '../../style/themes/default';
@import '../../style/mixins/index';

.@{ant-prefix}-carousel {
  .reset-component;

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
    }

    .slick-slide {
      pointer-events: none;

      &.slick-active {
        pointer-events: auto;
      }
    }
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
      content: '';
      display: table;
    }

    &:after {
      clear: both;
    }

    .slick-loading & {
      visibility: hidden;
    }
  }
  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir='rtl'] & {
      float: right;
    }
    img {
      display: block;
    }
    &.slick-loading img {
      display: none;
    }

    display: none;

    &.dragging img {
      pointer-events: none;
    }
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: @border-width-base @border-style-base transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  // Arrows
  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    margin-top: -10px;
    padding: 0;
    border: 0;
    outline: none;
    &:hover,
    &:focus {
      outline: none;
      background: transparent;
      color: transparent;
      &:before {
        opacity: 1;
      }
    }
    &.slick-disabled:before {
      opacity: 0.25;
    }
  }

  .slick-prev {
    left: -25px;
    &:before {
      content: '←';
    }
  }

  .slick-next {
    right: -25px;
    &:before {
      content: '→';
    }
  }

  // Dots
  .slick-dots {
    position: absolute;
    bottom: 12px;
    list-style: none;
    display: block;
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: @carousel-dot-height;
    li {
      position: relative;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      margin: 0 2px;
      padding: 0;
      button {
        border: 0;
        cursor: pointer;
        background: @component-background;
        opacity: 0.3;
        display: block;
        width: @carousel-dot-width;
        height: @carousel-dot-height;
        border-radius: 1px;
        outline: none;
        font-size: 0;
        color: transparent;
        transition: all 0.5s;
        padding: 0;
        &:hover,
        &:focus {
          opacity: 0.75;
        }
      }
      &.slick-active button {
        background: @component-background;
        opacity: 1;
        width: @carousel-dot-active-width;
        &:hover,
        &:focus {
          opacity: 1;
        }
      }
    }
  }
}

.@{ant-prefix}-carousel-vertical {
  .slick-dots {
    width: @carousel-dot-height;
    bottom: auto;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    height: auto;
    li {
      margin: 0 2px;
      vertical-align: baseline;
      button {
        width: @carousel-dot-height;
        height: @carousel-dot-width;
      }
      &.slick-active button {
        width: @carousel-dot-height;
        height: @carousel-dot-active-width;
      }
    }
  }
}

@import '../themes/default';

.reset-component() {
  font-size: @font-size-base;
  font-variant: @font-variant-base;
  font-feature-settings: 'tnum';
  line-height: @line-height-base;
  color: @text-color;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}
